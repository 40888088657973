import axios from 'axios';
import moment from 'moment';
import { getRequest } from './base';

// NOTE: Deprecated. please use `getProgramPdf`
function getProgramPDF(userId, layout, caseId, isLoginRestricted) {
  return getRequest(
    `${process.env.REACT_APP_LIMBER_PDF_API}/hep-pdfs/${userId}`,
    {
      params: {
        layout,
        caseId,
        isLoginRestricted,
      },
      headers: {
        'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
      },
    }
  );
}

export function getProgramPdf({
  caseId,
  isLoginRestricted,
  language,
  layout,
  userId,
}) {
  console.log('@--> language', language);
  return getRequest(
    `${process.env.REACT_APP_LIMBER_PDF_API}/hep-pdfs/${userId}`,
    {
      params: {
        layout,
        caseId,
        isLoginRestricted,
      },
      headers: {
        'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
        'Accept-Language': language,
      },
    }
  );
}

const getNotesFromCasePDF = async (userId, caseId, rangeStart, rangeEnd) => {
  return getRequest(
    `/participants/${userId}/cases/${caseId}/notes?rangeStart=${rangeStart}&rangeEnd=${rangeEnd}`,
    {
      headers: {
        Accept: 'application/pdf',
      },
    }
  ).then((response) => {
    if (response.status === 200) return response.data;
    return Promise.reject(response.statusText);
  });
};

// NOTE: Deprecated. Please use `downloadPdf`
function downloadPDF(file, participantLastName, programName) {
  return axios
    .get(file, {
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      responseType: 'blob',
    })
    .then((response) => {
      const a = document.createElement('a');
      const url = window.URL.createObjectURL(response.data);
      a.href = url;
      const today = moment().format('YYYYMMDD');
      a.download = `${participantLastName}-${programName}-${today}.pdf`;
      a.click();
    });
}

export const downloadPdf = async ({
  file,
  participantLastName,
  programName,
}) => {
  return axios
    .get(file, {
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      responseType: 'blob',
    })
    .then((response) => {
      const a = document.createElement('a');
      const url = window.URL.createObjectURL(response.data);
      a.href = url;
      const today = moment().format('YYYYMMDD');
      a.download = `${participantLastName}-${programName}-${today}.pdf`;
      a.click();
    });
};

// eslint-disable-next-line import/prefer-default-export
export { getProgramPDF, downloadPDF, getNotesFromCasePDF };
